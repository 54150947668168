import React, { useEffect, useState } from "react";
import {
    Box,
    Toolbar,
    Typography,
    TextField,
    Grid,
    IconButton,
    InputAdornment,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { toast } from "react-toastify";
import PrimaryButton from "../components/PrimaryButton";
import axios from "../api/axios";

const CashierLogin = () => {
    const navigate = useNavigate();
    const [cashierData, setCashierData] = useState({
        username: "",
        password: "",
    });
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false);
    const location = useLocation();
    const message = location?.state?.message;

    useEffect(() => {
        if (message === true) {
            navigate(location.pathname, { replace: true, state: {} });
            window.location.reload();
        }
    }, [navigate, location]);

    const handlePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCashierData((prev) => ({ ...prev, [name]: value }));
        setErrors((prev) => ({ ...prev, [name]: "" }))
    };

    const validateForm = () => {
        let formErrors = {};
        if (!cashierData.username.trim()) {
            formErrors.username = "Username is required";
        }
        if (!cashierData.password.trim()) {
            formErrors.password = "Password is required";
        }
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleLogin = async () => {
        if (!validateForm()) {
            return;
        }
        setIsLoading(true)
        try {
            const response = await axios.post(
                "auth/user/kiosk-outlet-user-signin",
                {
                    username: cashierData.username,
                    password: cashierData.password,
                }
            );
            if (response.status === 200) {
                const expiryTime = Date.now() + 20 * 60 * 60 * 1000;
                localStorage.setItem('tokenExpiry', expiryTime.toString());
                localStorage.setItem("authToken", response?.data?.data?.jwt);
                localStorage.setItem(
                    "outlet",
                    response?.data?.data?.user?.outlet?._id
                );
                const message = response?.data?.data?.message;
                toast.success(message, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
                navigate("/user-details");
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = '';
                if (
                    error.response?.status === 406
                ) {
                    errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                }
                else if (
                    error.response?.status === 412
                ) {
                    errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error
                }

                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        } finally {
            setIsLoading(false)
        }
    };

    return (
        <>
            <Box sx={{
                textAlign: "center", background: "#71bae5", minHeight: '100vh', display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center', position: 'relative', overflow: 'hidden'
            }}>
                <Box sx={{ position: 'absolute', top: 100 }}>
                    <img src="/assets/Vector-one.png" alt="cloud-one" />
                </Box>
                <Box sx={{ position: 'absolute', right: 0, top: 200 }}>
                    <img src="/assets/Vector-two.png" alt="cloud-one" />
                </Box>

                <Grid container justifyContent="center" alignItems={'center'} sx={{ flex: 1 }}>
                    <Grid item md={4} sm={6} xs={10}>
                        <img src='/assets/auth-brand-logo.png' alt="brand-logo" width={"100px"} />
                        <Typography sx={{ fontSize: "25px", margin: "10px 0 18px 0", color: 'white', fontWeight: 'bold' }}>
                            KIOSK Login
                        </Typography>
                        <Box sx={{ textAlign: "left" }}>
                            <label>Username</label>
                            <TextField
                                autoComplete="false"
                                fullWidth
                                name="username"
                                value={cashierData.username}
                                id="cashier"
                                sx={{ borderRadius: "8px", backgroundColor: "#fff" }}
                                placeholder="Tap here to type"
                                variant="outlined"
                                onChange={handleInputChange}
                                error={!!errors.username}
                            // helperText={errors.username}
                            />
                            <p style={{ fontSize: '12px', marginTop: '-1px', color: 'red' }}>{errors.username}</p>
                        </Box>
                        <Box sx={{ textAlign: "left", marginTop: "21px" }}>
                            <label >Password</label>
                            <TextField
                                autoComplete="false"
                                fullWidth
                                name="password"
                                sx={{ borderRadius: "8px", backgroundColor: "#fff" }}
                                placeholder="Tap here to type"
                                variant="outlined"
                                type={showPassword ? "text" : "password"}
                                value={cashierData.password}
                                onChange={handleInputChange}
                                error={!!errors.password}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={handlePasswordVisibility} edge="end">
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <p style={{ fontSize: '12px', marginTop: '-1px', color: 'red' }}>{errors.password}</p>
                        </Box>

                        <Grid
                            container
                            justifyContent={"center"}
                            style={{ marginTop: "21px" }}
                        >
                            <Grid item sm={12} md={12}>
                                <PrimaryButton
                                    title={"Proceed"}
                                    onClick={handleLogin}
                                    disabled={isLoading}
                                    spinner={isLoading}
                                    disabledTitle={"Logging In"}
                                    style={{
                                        borderRadius: "30px",
                                        fontSize: "18px",
                                        width: "100%",
                                        marginTop: "10px",
                                        marginBottom: "20px",
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Box sx={{ position: 'absolute', top: 600 }}>
                    <img src="/assets/Vector-one.png" alt="cloud-three" />
                </Box>

                <Box sx={{ position: 'absolute', top: 650, right: 0 }}>
                    <img src="/assets/Vector-two.png" alt="cloud-three" />
                </Box>
            </Box>
        </>
    );
};

export default CashierLogin;
