import axios from "axios";

//  DEVELOPMENT URL
// const BASE_URL = 'https://xpanse.dev.bexcart.com/'

// PRODUCTION URL
const BASE_URL = 'https://api.xpansecafe.com/'
// 
export default axios.create({
    baseURL: BASE_URL
})